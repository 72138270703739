/* Hook DisplaySlider */
.owl-slider {
  position: relative;

  .owl-dots {
    @include poa(null, 0, 1rem, 0);

    @media (min-width: $md-width) {
      bottom: 2rem;
    }
  }

  .owl-dots .owl-dot.active span,
  .owl-dots .owl-dot:hover span {
    background-color: #fff;
  }

  .advslide_player {
    height: 100%;
    position: relative;

    .advslide_player-link {
      display: block;
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
      z-index: 1;
    }
  }
}
.vimeo-wrapper {
  height: 100%;
  left: 0;
  overflow: hidden;
  pointer-events: none;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: -1;
}
.vimeo-wrapper iframe {
  height: 56.25vw;
  left: 50%;
  min-height: 100dvh;
  min-height: 100vh;
  min-width: 177.77vh;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100vw;

  @media (min-width: $sm-width) {
    min-height: 104vh;
  }
  @media (min-width: $lg-width) {
    min-height: 100vh;
  }
}

.vimeo-global {
  height: 100vh;
  height: 100dvh;

  @media (min-width: $sm-width) {
    height: 100vh;
  }
}

body {
  min-height: 100vh;

  /* mobile viewport bug fix */
  min-height: stretch;
}

html {
  height: stretch;
}
