/* Import base */
@import "../abstracts/*.scss";
/* Import vendor */
@import "../libraries/_owl-carousel";
/* Import component */
@import "../components/_wysiwyg";
@import "../components/_alert";
@import "../components/_block";
/* @import "../components/_btn"; */
@import "../components/_form";
@import "../components/_search";
@import "../components/_owl";
@import "../components/_page-heading";
@import "../components/_panels";
@import "../components/_popover";
@import "../components/_product-ctn";
@import "../components/_product-row";
@import "../components/_table-div";
@import "../components/_steps";
@import "../components/_tile";
@import "../components/_listing";
@import "../components/_jauge-icons";
@import "../components/_collapse";
@import "../components/_slider";
/* Import layout */
@import "../layouts/_header";
@import "../layouts/_footer";
/* Import module */
@import "../modules/advpopinlogin/advpopinlogin";
@import "../modules/cookiesinfos/cookiesinfos";
@import "../modules/blockcart/_blockcart";
@import "../modules/blockmyaccount/_blockmyaccount";
@import "../modules/blocklanguages/_blocklanguages";
@import "../modules/advfeaturedcategories/_advfeaturedcategories";
@import "../modules/advslider/_advslider-home";

.home-left-hook {
  @media (min-width: $sm-width) {
    border-right: 1px solid color(grey, light);
    padding: 0 4vw;
  }
}

.home-right-hook {
  @media (min-width: $sm-width) {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    position: relative;

    @media (min-width: $lg-width) {
      padding: 0 4vw;
    }
  }

  .advconstructor:nth-of-type(1) {
    border-bottom: 1px solid color(grey, light);
    border-top: 1px solid color(grey, light);
    margin-bottom: 50px;
    margin-top: 50px;
    padding-bottom: 50px;
    padding-top: 50px;

    @media (min-width: $sm-width) {
      border: 0;
      margin-top: inherit;
      padding: 0;
    }
  }
}

.advconstructor {
  a {
    display: block;
    overflow: hidden;

    img {
      -webkit-transform: scale(1);
              transform: scale(1);
      -webkit-transition: 0.3s;
      transition: 0.3s;
    }

    &:hover {
      img {
        -webkit-transform: scale(1.05);
                transform: scale(1.05);
      }
    }
  }
}

.home-bottom-hook {
  border-top: 1px solid color(grey, light);
  margin-top: 4vw;
  padding-bottom: 4vw;
  padding-top: 4vw;

  .infos-cms {
    width: 65%;
  }
}
