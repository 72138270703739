.block-valid-change {
  .block-std-footer {
    > div {
      margin-bottom: 15px;
    }
  }
}

// select
.custom-select {
  //background: red;
  position: relative;

  > label {
    color: color(mono, dark);
    display: block;
    margin-bottom: 7px;
    text-align: left;

    @include rem(12);

    @media (min-width: $xs-width) {
      margin-bottom: 10px;
    }
    @media (min-width: $sm-width) {
      font-size: default(font-size);
    }
    @media (min-width: $md-width) {
      font-size: default(font-size);
    }
  }

  > span {
    background-color: color(mono, light);
    border: 1px solid color(border);
    color: color(text);
    cursor: pointer;
    display: block;
    font-size: 14px;
    height: height(form, select);
    line-height: height(form, select);
    overflow: hidden;
    padding: 0 50px 0 default(padding, form-mobile);
    position: relative;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
    z-index: 1;

    @media (min-width: $sm-width) {
      padding: 0 50px 0 default(padding, form);
    }

    &::after {
      border-bottom: none;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-top: 5px solid black;
      color: color(text);
      content: "";
      cursor: pointer;
      display: block;
      font-family: default(font-family, icon);
      font-size: 30px;
      font-style: normal;
      height: 0;
      line-height: 1;
      opacity: 1;
      position: absolute;
      right: 20px;
      top: 50%;
      width: 0;
    }

    &.open {
      border-color: color(grey, base);

      &::after {
        border: 0;
        content: "\e909";
        font-size: 13px;
        margin-top: -5px;
        opacity: 1;
        right: 18px;
        width: 10px;
      }
    }
  }

  > ul {
    background: color(mono, light);
    border: 1px solid color(border);
    display: none;
    margin: -1px 0 0 0;
    max-height: 300px;
    overflow-x: hidden;
    overflow-y: auto;
    padding: 0;
    position: absolute;
    z-index: 10;

    li {
      color: color(text);
      cursor: pointer;
      font-size: 14px;
      padding: 12px default(padding, form);

      input {
        height: 30px;
        width: 100%;
      }

      &:hover,
      &.selected {
        // background-color: color(background);
        font-weight: default(weight, bold);
      }
    }

    &.open {
      display: block;
    }
  }

  &.disabled > span {
    cursor: not-allowed;
    opacity: 0.5;
  }

  &.disabled > label .label {
    cursor: not-allowed;
    opacity: 0.5;
  }

  & > select {
    display: none;
  }
}

// checkbox
.custom-checkbox {
  @include custom-checkbox-radio;

  .ipanel {
    text-decoration: underline;
  }

  &::before {
    content: icon(checkbox);
    height: height(form, checkbox);
    width: height(form, checkbox);
  }

  &.checked::before {
    border-color: color(border, dark);
    content: icon(checkbox-checked);
  }

  &.inline {
    align-items: center;

    &::before {
      display: inline-block;
      margin-right: 10px;
      vertical-align: top;
    }

    label {
      display: inline-block;
      letter-spacing: 0;
      line-height: 18px;
      margin-bottom: 0;
      padding-top: 1px;
      text-transform: none;
      vertical-align: top;
    }
  }
}

// radio
.custom-radio {
  @include custom-checkbox-radio;

  &::before {
    content: icon(radio);
    height: height(form, radio);
    width: height(form, radio);
  }

  &.checked {
    &::before {
      content: icon("radio-checked");
    }
  }

  &.inline {
    align-items: center;
    display: flex;

    label {
      margin-bottom: 0;
      max-width: 100%;
      text-transform: none;
    }
  }

  &.tab {
    display: inline-block;

    &:before {
      display: none;
    }

    &:after {
      @include poa(auto, 0, -16px, 0);
      border-bottom: 1px solid transparent;
      content: "";
      height: 1px;
      transition: 0.3s;
      width: auto;
    }

    &.checked {
      &::after {
        border-color: color(border, dark);
      }
    }

    input {
      height: 5px;
      left: 0;
      max-width: none;
      right: 0;
      width: 100%;
    }

    label {
      @include heading(16, false, false);
      font-weight: normal;
      line-height: 2.6rem;

      @media (min-width: $md-width) {
        @include rem(24);
        line-height: 3.2rem;
      }
    }
  }
}

// CUSTOM RADIO ET CHECKBOX SPECIAUX (qui ont une forme de bouton)
.input-button {
  height: height(form, input-radio);

  .custom-checkbox,
  .custom-radio {
    padding: 0;

    &::before,
    &::after {
      content: none;
    }
  }

  .custom-radio,
  .custom-checkbox,
  label {
    height: 100%;

    &:hover {
      background-color: color(primary, base);
      color: color(secondary, base);
      position: relative;
    }
  }

  .checked {
    label {
      background-color: color(background, dark);
      border-color: color(background, dark);
      color: color(text, light);
    }
  }

  &:hover {
    color: #000;
  }

  input {
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    width: 100%;
  }

  label {
    background-color: color(mono, light);
    border: 1px solid color(primary);
    color: color(text);
    display: flex;
    flex-direction: column;
    font-size: 1.4rem;
    justify-content: center;
    padding: 0.9rem;
    text-align: center;
    transition: background-color 0.2s;
    width: 100%;
  }

  &:hover {
    label {
      background-color: color(primary, base);
      color: color(secondary, base);
    }
  }

  .checked label {
    background-color: #000;
  }
}

// CUSTOM FILE (CONTACT PAGE)
.custom-file {
  height: height(form, file);
  position: relative;

  label {
    height: 2rem;
  }

  .form-control {
    cursor: pointer;
    height: height(form, file);
    opacity: 0;
    width: 100%;
    z-index: 1;

    @include poa(auto, 0, 0, 0);
  }

  .filename {
    background-color: color(mono, light);
    border: 1px solid color(border);
    padding: 1px default(padding, form) 0;
    width: calc(100% - 80px);
  }

  .filename,
  .action {
    display: inline-block;
    height: height(form, file);
    left: 0;
    line-height: 5rem;
    margin: 0;
    position: absolute;
    text-align: left;
    top: 0;

    @include rem(16);
  }

  .action {
    background-color: transparent;
    border: 1px solid color(border);
    border-left: 0;
    cursor: pointer;
    left: calc(100% - 80px);
    padding: 1rem;
    text-align: center;
    transition: border 0.3s ease-in-out;
    width: 80px;

    &::after {
      content: icon(file);
      font-family: default(font-family, icon);
      font-size: 20px;

      @extend %full-center;
    }

    &:hover {
      border-color: color(background, dark);
    }
  }
}

.radio-box {
  cursor: pointer;

  > label {
    display: block;
  }

  > input {
    cursor: pointer;
    height: 49px;
    opacity: 0;
    position: absolute;
    width: 100%;
  }

  .filename {
    background: #f2eceb;
    border: 0;
    color: #bba3a2;
    display: inline-block;
    font-size: 14px;
    height: 36px;
    line-height: 36px;
    margin-right: 10px;
    max-width: 60%;
    overflow: hidden;
    padding: 0 15px;
    text-overflow: ellipsis;
    vertical-align: middle;
    white-space: nowrap;
  }

  .action {
    color: #bba3a2;
    display: inline-block;
    font-family: "fjalla";
    vertical-align: middle;

    &::after {
      color: #ceb5b4;
      content: "\e925";
      display: inline-block;
      font-family: default(font-family, icon);
      font-size: 20px;
      margin-left: 6px;
      position: relative;
      top: 3px;
    }
  }
}

.label-required {
  @include rem(11);
  @include poa(5px, 5px, null, null);

  @media (min-width: $sm-width) {
    @include rem(12);
  }
  @media (min-width: $md-width) {
    @include poa(auto, 5px, 5px, null);
  }
}

/*******************************************   */
.form-group {
  margin-bottom: 15px;

  @media (min-width: $sm-width) {
    margin-bottom: 20px;
  }
  @media (min-width: $md-width) {
    margin-bottom: 30px;
  }

  .label-std {
    display: inline-block;
    font-weight: default(weight, medium);
    margin-bottom: 7px;

    @include rem(12);

    @media (min-width: $xs-width) {
      margin-bottom: 10px;
    }
    @media (min-width: $sm-width) {
      font-size: default(font-size);
    }
    @media (min-width: $md-width) {
      font-size: default(font-size);
    }
  }

  .form-control {
    border: 1px solid color(border);
    font-size: 1.4rem;
    height: height(form, text);
    padding: 0 default(padding, form);
    width: 100%;

    &:focus,
    &:active {
      border-color: color(secondary, light);
      outline: none;
    }
  }

  .form-sign {
    @include poa(0, 1rem, 0, null);
    display: block;
    height: 1.5rem;
    margin: auto;

    &-ctn {
      position: relative;
    }
  }

  // SEARCH FORM
  &.search-form {
    position: relative;

    &:active,
    &:focus-within {
      button {
        bottom: 1px;
        right: -5px;

        &:before {
          content: "\e91d";
          font-size: 40px;
        }
      }
    }

    input {
      border: 0;
      border-bottom: 2px solid #000;
      font-family: default(font-family);
      font-size: 1.6rem;
      padding: 0;
    }

    button {
      background-color: transparent;
      border: none;
      outline: none;
      padding: 0;
      position: absolute;
      right: 0;
      transform: rotateY(180deg);
      width: 5rem;

      &:before {
        color: #000;
        content: "\e91c";
        font-family: "icomoon";
        font-size: 25px;
      }
    }
  }

  // input-group
  &.input-group {
    label {
      left: default(padding, form);
    }

    .input-group-control {
      // padding-right: 0;

      .form-control {
        border-right: 0;
      }
    }

    .input-group-append {
      padding-left: 0;

      .btn {
        border-bottom-left-radius: 0;
        border-top-left-radius: 0;
        height: 100%;
        padding-bottom: 0;
        padding-top: 0;
      }
    }
  }

  &.password-valid {
    margin-top: 20px;
  }

  &.form-ok .form-control {
    border-color: color(alert, success);
    color: color(alert, success);
  }

  &.form-error .form-control {
    border-color: color(alert, danger);
    color: color(alert, danger);
  }

  // FORM-GROUP AYANT DES INPUTS TYPE TEXT / PASSWORD / NUMBER, etc. / A ANIMER
  &-animated,
  &-animated-select {
    label {
      left: default(padding, form-mobile) + break(xxs, gutter);
      position: absolute;
    }
  }

  &-animated {
    margin-bottom: 1rem;
    position: relative;

    label {
      @include label-base;
      left: default(padding, form-mobile) + break(xxs, gutter);
      margin: 0;
      max-width: 80%;
      pointer-events: none;
      position: absolute;

      @extend %vertical-center;

      @include animated();

      @media (min-width: $md-width) {
        left: default(padding, form) + break(md, gutter);
      }
      @media (min-width: $sm-width) {
        font-size: default(font-size);
      }
      @media (min-width: $md-width) {
        font-size: 1.4rem;
      }

      &.not-empty {
        @include label-animated;
      }
    }

    .form-control {
      // si label  mis après input, meilleure fonctionnement en cas d'autocomplete
      &:-webkit-autofill,
      &:-internal-autofill-selected {
        background-color: #fff;

        + label {
          @include label-animated;
        }
      }
    }

    // OEIL pour voir les PASSWORDS
    .see-pwd {
      cursor: pointer;
      position: absolute;
      right: 3rem;

      @extend %vertical-center;

      &.on {
        &::before {
          background: none;
          content: icon(eye-open);
        }
      }
    }

    .form-control {
      border: 1px solid color(border);
      font-family: default(font-family);
      font-size: 1.4rem;
      height: height(form, text);
      padding: 3px default(padding, form-mobile) 0;
      width: 100%;

      &:hover {
        border-color: #cecece;
      }

      @media (min-width: $md-width) {
        padding: 3px default(padding, form) 0;
      }
    }
  }

  // FORM-GROUP CONTENANT SELECT
  &-animated-select {
    margin-bottom: 1rem;
    position: relative;

    label {
      @include label-base;
      @include label-animated;
      font-size: 1rem;
      left: default(padding, form);
      margin-bottom: 0;
      z-index: 2;
    }

    .custom-select > span {
      line-height: 3.5;
      padding: 3px 30px 0 default(padding, form);
    }
  }

  &-animated .custom-file {
    position: relative;

    label {
      @include label-base;
      @include label-animated;
      left: default(padding, form);
      z-index: 2;
    }
  }

  // FORM-GROUP CONTENANT UN TEXTAREA
  &-animated-textarea {
    label {
      top: 2rem;
    }

    .form-control {
      min-height: 75px;
      padding-top: 1.5rem;
    }
  }

  // FORM-GROUP WITH MENTION
  .helper-text {
    font-size: 1.1rem;
  }

  &.with-helper-text {
    margin-bottom: 4rem;

    .helper-text {
      margin-top: 0.5rem;
      padding-left: break(xxs, gutter);
      position: absolute;

      @media (min-width: $md-width) {
        padding-left: break(md, gutter);
      }
    }
  }
}
