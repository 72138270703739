.step {
  border-radius: 3rem;
  display: block;
  position: relative;
  text-align: center;

  &-label {
    display: inline-block;
    font-size: 1.2rem;
    margin-left: 0;
  }

  &-number {
    @include sizePx(25);
    border: 1px solid color(mono, dark);
    border-radius: 3.5rem;
    display: inline-block;
    line-height: 2.5rem;
    margin-right: 0.5rem;
    text-align: center;

    @media (min-width: $md-width) {
      @include sizePx(40);
      line-height: 4rem;
      margin-right: 1rem;
    }
  }

  &-done {
    .step-number {
      background-color: color(secondary);
      font-size: 0;

      &::after {
        color: color(text, primary);
        content: icon(check);
        font-family: "icomoon";
        font-size: 0.7rem;
      }
    }

    .step-label {
      color: color(text);
    }
  }

  &-current {
    .step-number {
      background-color: color(primary);
      color: color(text, light);
    }

    .step-label {
      color: color(text, primary);
    }
  }

  &-todo {
    .step-number {
      color: color(text);
    }

    .step-label {
      color: color(text);
    }
  }
}
