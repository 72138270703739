@use "sass:math";

.section-block {
  padding: 50px 0;

  .section-block-header {
    text-align: center;

    .section-block-header-title {
      margin-bottom: 0;
    }

    .section-block-header-subtitle {
      margin-bottom: 30px;
    }
  }
}

// like addresses / CBs blocks (account and cart)
.block-info {
  border: 1px solid color(border);
  position: relative;

  &-ctn {
    margin-bottom: 1.5rem;

    @media (min-width: $md-width) {
      margin-bottom: 3rem;
    }
  }

  &-inner {
    border-radius: default(radius);
    margin: 0;
    padding: 0 1.5rem 1.5rem;

    @media (min-width: $md-width) {
      padding: 0 3rem 3rem;
    }
  }

  &-inner,
  &-content {
    font-size: 1.2rem;
    line-height: 1.8rem;

    @media (min-width: $md-width) {
      font-size: 1.4rem;
      line-height: 2rem;
    }
  }

  &-title,
  &-alias {
    color: color(mono, dark);
    display: block;
    font-size: 1.4rem;
    margin-bottom: 0.5rem;

    @media (min-width: $md-width) {
      font-size: 1.6rem;
    }
  }

  &-title {
    padding: 1.5rem 1.5rem 0;

    @media (min-width: $md-width) {
      padding: 3rem 3rem 0;
    }
  }

  &-footer {
    margin-top: 1.5rem;
  }
}

.block {
  display: block;
  margin-bottom: 15px;
}

/**** BLOC BLOC CATEG / MINI ****/

.bloc-categ {
  margin-bottom: 5.5rem;
  position: relative;

  a {
    border: 1px solid color(border, light);
    display: block;
  }

  img {
    display: block;
    margin: auto;
    width: 100%;
  }

  p {
    font-size: 1.8rem;
    line-height: 1.8rem;
    margin: 2rem 0;
    padding: 0 1rem;
    text-align: center;

    @media (min-width: $md-width) {
      font-size: 2.4rem;
      line-height: 2.4rem;
      margin-bottom: 3rem;
      margin-top: 5rem;
    }
  }

  a {
    border: 1px solid color(border);
  }

  &:hover {
    a {
      border: 1px solid color(grey, base);

      &:after {
        bottom: -40px;
        content: "\e91f";
        font-family: "icomoon";
        font-size: 1.2rem;
        left: 50%;
        position: absolute;
        transform: translateX(-50%);
      }
    }
  }
}

/**** IMAGES CUSTOM ****/

.two-squares {
  position: relative;

  &:before {
    background: #fff;
    content: "";
    height: 23px;
    left: 0;
    position: absolute;
    top: 0;
    width: 23px;
    z-index: 1;

    @media (min-width: $md-width) {
      height: 65px;
      width: 65px;
    }
  }

  &:after {
    background: #fff;
    bottom: 0;
    content: "";
    height: 23px;
    left: 0;
    position: absolute;
    width: 23px;
    z-index: 1;

    @media (min-width: $md-width) {
      height: 65px;
      width: 65px;
    }
  }
}

.two-corners {
  overflow: hidden;
  position: relative;

  &:before {
    background: #fff;
    border-radius: 100px;
    content: "";
    height: 40px;
    position: absolute;
    right: -20px;
    top: -20px;
    width: 40px;
    z-index: 1;

    @media (min-width: $md-width) {
      height: 90px;
      right: -45px;
      top: -45px;
      width: 90px;
    }
  }

  &:after {
    background: #fff;
    border-radius: 100px;
    content: "";
    height: 40px;
    left: -20px;
    position: absolute;
    top: -20px;
    width: 40px;
    z-index: 1;

    @media (min-width: $md-width) {
      height: 90px;
      left: -45px;
      top: -45px;
      width: 90px;
    }
  }
}

.four-corners-ctn {
  overflow: hidden;
  position: relative;

  &:before {
    background: #fff;
    border-radius: 100px;
    content: "";
    height: 40px;
    position: absolute;
    right: -20px;
    top: -20px;
    width: 40px;
    z-index: 1;

    @media (min-width: $md-width) {
      height: 90px;
      right: -45px;
      top: -45px;
      width: 90px;
    }
  }

  &:after {
    background: #fff;
    border-radius: 100px;
    bottom: -20px;
    content: "";
    height: 40px;
    position: absolute;
    right: -20px;
    width: 40px;
    z-index: 1;

    @media (min-width: $md-width) {
      bottom: -45px;
      height: 90px;
      right: -45px;
      width: 90px;
    }
  }
}

.four-corners {
  position: relative;

  &:before {
    background: #fff;
    border-radius: 100px;
    content: "";
    height: 40px;
    left: -20px;
    position: absolute;
    top: -20px;
    width: 40px;
    z-index: 1;

    @media (min-width: $md-width) {
      height: 90px;
      left: -45px;
      top: -45px;
      width: 90px;
    }
  }

  &:after {
    background: #fff;
    border-radius: 100px;
    bottom: -20px;
    content: "";
    height: 40px;
    left: -20px;
    position: absolute;
    width: 40px;
    z-index: 1;

    @media (min-width: $md-width) {
      bottom: -45px;
      height: 90px;
      left: -45px;
      width: 90px;
    }
  }
}

/***** CMS BLOCK *****/

.cms-bloc {
  position: relative;

  @media (min-width: $md-width) {
    &:hover {
      .infos-cms::after {
        opacity: 1;
      }
    }
  }

  .infos-cms {
    background: #fff;
    font-size: 1.4rem;
    margin: -3rem auto 0;
    padding: 1.5rem;
    position: relative;
    text-align: center;
    width: 85%;
    z-index: 1;

    @media (min-width: $md-width) {
      font-size: 1.6rem;
      margin-top: -8rem;
      padding: 20px;

      &::after {
        content: icon(carres_points);
        display: block;
        font-family: default(font-family, icon);
        font-size: 1.1rem;
        margin-top: 3rem;
        opacity: 0;
        transition: 0.3s;
      }
    }
  }

  .title-cms {
    font-size: 1.8rem;
    margin-bottom: 1rem;

    @media (min-width: $md-width) {
      font-size: 2.4rem;
    }
  }
}

.infos-cms.bloc-cms2 {
  padding: 2rem 3rem;

  @media (min-width: $lg-width) {
    padding: 3rem 10%;
  }

  p {
    font-size: 1.6rem;
    line-height: 2.2rem;

    @media (min-width: $md-width) {
      font-size: 2.2rem;
      line-height: 3rem;
    }
  }

  .h3 {
    font-size: 1.6rem;
    line-height: 1.3;

    @media (min-width: $lg-width) {
      font-size: 2rem;
    }
    @media (min-width: $xl-width) {
      font-size: 2.2rem;
    }
  }

  .title-cms {
    font-size: 1.4rem;

    @media (min-width: $md-width) {
      font-size: 1.6rem;
    }
  }
}

.designer-bloc {
  .title-cms {
    margin-bottom: 0;
    margin-top: 1rem;
  }

  .infos-designer {
    background: #fff;
    font-size: 1.4rem;
    max-width: 330px;
    padding: 15px 10px;
    text-align: center;
    width: 100%;

    > p:nth-of-type(1) {
      padding-bottom: 5px;
    }

    @media (min-width: $md-width) {
      font-size: 1.6rem;
      padding: 20px 10px;
    }
  }

  &:hover {
    &:after {
      bottom: -10px;
      color: #0050b5;
    }
  }
}

/***** LISTING BLOC CMS ****/

/**** COMMON ***/
.listing-cms {
  .title-cms {
    font-size: 2rem;
    width: 100%;

    @media (min-width: $md-width) {
      font-size: 2.4rem;
    }
    @media (min-width: $xxl-width) {
      width: auto;
    }
  }

  .right-cms {
    flex-grow: 1;
    font-size: 1.4rem;
    margin-top: 10px;

    @media (min-width: $md-width) {
      font-size: 1.6rem;
    }
    @media (min-width: $xxl-width) {
      text-align: right;
    }
  }

  .infos-listing {
    align-items: flex-end;
    justify-content: space-between;
    margin-top: 32px;
    position: relative;

    @media (min-width: $md-width) {
      margin-top: 60px;
    }
    @media (min-width: $lg-width) {
      margin-top: 30px;
    }
    @media (min-width: $xxl-width) {
      flex-wrap: nowrap;
    }
  }
}

.cms-bloc-listing {
  img {
    transform: scale(1);
    transition: 0.3s;
  }

  &:hover {
    img {
      transform: scale(1.05);
    }
  }
}

.cms-bloc-listing:nth-of-type(n + 2) {
  position: relative;

  .infos-listing {
    &:before {
      content: icon(carres_points);
      font-family: "icomoon";
      font-size: 10px;
      position: absolute;
      top: -20px;

      @media (min-width: $md-width) {
        top: -32px;
      }
      @media (min-width: $lg-width) {
        top: -10px;
      }
    }
  }

  .img-cms-listing {
    overflow: hidden;
    position: relative;

    &:before {
      background: #fff;
      border-radius: 100px;
      content: "";
      height: 40px;
      left: auto;
      position: absolute;
      right: -20px;
      top: -20px;
      width: 40px;
      z-index: 1;

      @media (min-width: $md-width) {
        height: 90px;
        right: -45px;
        top: -45px;
        width: 90px;
      }
    }

    &:after {
      background: #fff;
      border-radius: 100px;
      content: "";
      height: 40px;
      left: -20px;
      position: absolute;
      top: -20px;
      width: 40px;
      z-index: 1;

      @media (min-width: $md-width) {
        height: 90px;
        left: -45px;
        top: -45px;
        width: 90px;
      }
    }
  }
}

.cms-bloc-listing:nth-of-type(3n) {
  .infos-listing {
    &:before {
      content: "\e924";
      font-family: "icomoon";
      font-size: 20px;
      position: absolute;
      top: -27px;
      transform: none;

      @media (min-width: $md-width) {
        top: -40px;
      }
      @media (min-width: $lg-width) {
        top: -15px;
      }
    }
  }

  .img-cms-listing {
    position: relative;

    &:before {
      background: #fff;
      border-radius: 0;
      bottom: 0;
      content: "";
      height: 24px;
      left: 0;
      position: absolute;
      top: auto;
      width: 23px;
      z-index: 1;

      @media (min-width: $md-width) {
        height: 50px;
        width: 50px;
      }
    }

    &:after {
      background: #fff;
      border-radius: 0;
      bottom: auto;
      content: "";
      height: 23px;
      left: auto;
      position: absolute;
      right: 0;
      top: 0;
      width: 23px;
      z-index: 1;

      @media (min-width: $md-width) {
        height: 50px;
        width: 50px;
      }
    }
  }
}

.cms-bloc-listing:nth-of-type(3n - 2) {
  .infos-listing {
    &:before {
      content: "\e923";
      font-family: "icomoon";
      font-size: 10px;
      left: 0;
      position: absolute;
      top: -21px;

      @media (min-width: $md-width) {
        top: -30px;
      }
      @media (min-width: $lg-width) {
        top: -9px;
      }
    }
  }

  .img-cms-listing {
    line-height: 0;
    overflow: hidden;
    position: relative;

    &::after,
    &::before {
      border-radius: 0;
    }

    &:before {
      background: #fff;
      content: "";
      height: 24px;
      left: 0;
      position: absolute;
      top: 0;
      width: 23px;
      z-index: 1;

      @media (min-width: $md-width) {
        height: 50px;
        width: 50px;
      }
    }

    &:after {
      background: #fff;
      bottom: 0;
      content: "";
      height: 23px;
      left: unset;
      position: absolute;
      right: 0;
      top: unset;
      width: 23px;
      z-index: 1;

      @media (min-width: $md-width) {
        height: 50px;
        width: 50px;
      }
    }
  }
}

/**** CMS BLOC IMG DROITE ****/
.bloc-img-droite {
  flex-wrap: wrap;
  position: relative;

  @media (min-width: $md-width) {
    flex-wrap: nowrap;
    margin-left: 0;
    margin-right: 0;
  }

  .ctn-img-cms {
    position: relative;
    width: 100%;

    @media (min-width: $md-width) {
      width: auto;
    }
  }

  .bg-white {
    line-height: 2.4rem;
    margin: 0 15px 0 15px;
    padding: 1.5rem;
    position: relative;
    text-align: center;
    width: auto;
    z-index: 2;

    @media (min-width: $md-width) {
      display: flex;
      flex-direction: column;
      justify-content: center;
      line-height: 3rem;
      margin: 0 (-#{math.div($column-width, 2)}rem) 0 0;
      min-height: 500px;
      padding: 3rem (math.div($column-width, 2) + $gutter) 3rem 0;
      text-align: left;
    }
    @media (min-width: $lg-width) {
      margin: 0 (-#{$column-width}rem) 0 0;
      min-height: 600px;
      padding: 3rem ($column-width + $gutter) 3rem 0;
    }
  }

  .content-cms {
    &::before {
      content: icon(trois_petits_points);
      display: block;
      font-family: default(font-family, icon);
      font-size: 2rem;
    }

    &::before {
      margin-bottom: 1rem;

      @media (min-width: $md-width) {
        margin-bottom: 3rem;
      }
    }

    h2 {
      margin-top: 1rem;

      @media (min-width: $md-width) {
        margin-top: 3rem;
      }
    }

    .h2 {
      margin-bottom: 15px;
      position: relative;

      @media (min-width: $md-width) {
        margin-bottom: 3rem;
      }
    }
  }

  .content-img-droite {
    @media (min-width: $lg-width) {
      margin-bottom: 3rem;
    }
  }

  &.reverse {
    .bg-white {
      margin: -5rem 15px 0 15px;

      @media (min-width: $md-width) {
        margin: 0 0 0 (-#{math.div($column-width, 2)}rem);
        padding: 3rem 0 3rem (math.div($column-width, 2) + $gutter);
      }
      @media (min-width: $lg-width) {
        margin: 0 0 0 (-#{$column-width}rem);
        padding: 3rem 0 3rem ($column-width + $gutter);
      }
    }
  }

  &-text {
    @media (min-width: $md-width) {
      padding-right: 1.5rem;
    }

    &::after {
      content: icon(trois_petits_points);
      display: block;
      font-family: default(font-family, icon);
      font-size: 2rem;
      margin-top: 2rem;
      z-index: 100;
    }
  }
}

.block-img-full-text {
  .content-cms {
    margin: -1.5rem auto 0;
    position: relative;
    width: 90%;

    @media (min-width: $md-width) {
      margin-top: -7rem;
    }

    &::before {
      content: icon(trois_petits_points);
      display: block;
      font-family: default(font-family, icon);
      font-size: 2rem;
      margin-bottom: 1rem;

      @media (min-width: $md-width) {
        margin-bottom: 3rem;
      }
    }
  }
}

.cms-block-half {
  & ~ .cms-block-half {
    @media (min-width: $sm-width) {
      margin-top: 17rem;
    }
  }

  &-content {
    &::before {
      content: icon(losange_points);
      display: block;
      font-family: default(font-family, icon);
      margin-bottom: 2rem;
    }
  }
}
