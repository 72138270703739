.advslider-home {
  position: relative;

  .arrow-page-down {
    font-size: 3rem;
    inset: 0 0 0 0;
    position: absolute;
    text-align: center;
    z-index: 1;

    &-wrap {
      padding: 1rem 0;
      position: absolute;
      width: 100%;
    }

    &-inner {
      height: 100%;
      max-height: 100vh;
      position: relative;
    }

    .content-arrow {
      cursor: pointer;
      height: 8rem;
      inset: auto 0 0 0;
      position: absolute;
    }

    img {
      animation: jumpInfinite 1.5s infinite;
      max-width: 4rem;
    }
  }

  @keyframes jumpInfinite {
    0% {
      margin-top: 0;
    }
    50% {
      margin-top: 20px;
    }
    100% {
      margin-top: 0;
    }
  }
}
