@import "../modules/blocknewsletter/_blocknewsletter";
@import "../modules/advtarteaucitron/_advtarteaucitron";

.footer-main {
  background: color(primary, base);
  color: color(secondary, base);

  @media (min-width: $sm-width) {
    padding: 0 4vw;
  }

  &-ctn {
    margin-top: auto;
  }

  .footer-inner {
    padding: 2.5rem 0 2rem 0;

    @media (min-width: $sm-width) {
      padding: 2.5rem 0 1rem 0;
    }
    @media (min-width: $md-width) {
      padding-top: 5rem;
    }
  }

  .footer-bottom {
    padding: 1.5rem 0;
    text-align: center;
  }
}

.footer-logo {
  margin-bottom: 2rem;

  @media (min-width: $md-width) {
    margin-bottom: 0;
  }

  svg {
    background: color(primary);
    display: block;
    fill: color(secondary);
    margin: auto;
    max-width: 135px;

    @media (min-width: $md-width) {
      margin: 0;
    }
  }
}

.footer-links {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  @media (min-width: $md-width) {
    justify-content: start;
  }

  .advblocklink {
    margin: 0 1rem 1.5rem 1rem;

    @media (min-width: $md-width) {
      margin: 0 4rem 1.5rem 0;
    }
  }
}

.footer-social {
  display: flex;
  justify-content: flex-end;

  .social {
    display: block;
    font-size: 2.5rem;
    margin: 2rem auto auto;

    @media (min-width: $md-width) {
      font-size: 1.4rem;
      margin: 0;
    }
  }

  .social-item {
    margin-right: 10px;
  }
}

.site-reinsurance {
  .icon {
    font-size: 20px;
  }
}
