.newsletter {
  &-ctn {
    display: none;

    @media (min-width: $md-width) {
      padding: 0 1.5rem;
    }

    &.open {
      display: block;
    }
  }
  &-inner {
    background: color(mono, dark);
    border-bottom: 1px solid rgba(255, 255, 255, 0.3);
    margin-bottom: 3rem;
    padding: 4rem 30px 3rem 30px;
    position: relative;

    @media (min-width: $xs-width) {
      padding: 30px 15%;
    }
    @media (min-width: $md-width) {
      margin-bottom: 4.5rem;
      padding: 0 35% 5rem 35%;
    }

    form {
      margin-bottom: 0;
      position: relative;
    }

    .newsletter-input {
      background: color(mono, dark);
      border: 0;
      border-bottom: 1px solid color(mono, light);
      color: color(mono, light);
      font-family: default(font-family);
      font-size: 1.6rem;
      height: 3rem;
      padding: 0 30px 0 0;
    }

    .newsletter-submit {
      @include poa(0, 0, 0, null);
      color: color(mono, light);
      font-size: 2.2rem;
      padding: 0;
    }
  }

  &-toggle {
    @include poa(0, 1.5rem);
    color: color(mono, light);

    @media (min-width: $sm-width) {
      right: 0;
    }
  }
}
