/* displayPanel */
.panel-cart-layout {
  .panel-product-remove-ctn {
    position: absolute;
    right: 0;
    top: 0;

    .icon {
      @include rem(18);
    }
  }

  .panel-cart-prices {
    .panel-cart-prices-line {
      padding: 5px 0;

      .value {
        text-align: right;
      }
    }
  }
}
