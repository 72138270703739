.advfeaturedcategories {
  .owl-stage {
    display: flex;
  }

  .featured-categories {
    margin: 0 -1rem;
    overflow: auto;
    padding: 1.5rem;

    @media (max-width: $sm-width-max) {
      display: flex;
    }
    @media (min-width: $sm-width) {
      flex-wrap: wrap;
      margin: 0 -1.5rem;
    }
    @media (min-width: $md-width) {
      margin: 0;
      padding: 0;
    }
  }

  .featured-category {
    cursor: pointer;
    margin-bottom: 15px;
    min-width: 45%;
    padding: 0 1rem;

    @media (min-width: $sm-width) {
      height: 100%;
      margin-bottom: 0;
      min-width: 32%;
      padding-bottom: 60px;
    }
    @media (min-width: $lg-width) {
      min-width: 24%;
    }
  }

  .bloc-categ {
    height: 100%;
    margin-bottom: 0;

    a {
      display: flex;
      flex-direction: column;
      height: 100%;
      padding: 5px;
      text-align: center;
    }

    img {
      margin: 0 auto;
      max-width: 80%;
      width: auto;
    }

    p {
      font-size: 1.6rem;
      margin-bottom: 0;
      margin-top: 0;
      padding: 0 1.5rem;

      @media (min-width: $xl-width) {
        font-size: 2.4rem;
        margin: 2rem 0 1rem 0;
        padding: 0 5rem;
      }
    }
  }
}

.category-title p {
  font-size: 1.8rem;
  margin: 1.5rem auto 2rem;
  text-align: center;

  @media (min-width: $xl-width) {
    font-size: 2.4rem;
    margin-bottom: 0;
    margin-top: 0;
    padding-top: 50px;
  }
}
