.wysiwyg {
  font-size: 1.4rem;
  letter-spacing: 0.04rem;
  line-height: 24px;

  @media (min-width: $md-width) {
    font-size: 1.6rem;
    line-height: 30px;
  }

  ul {
    padding: 5px 15px;

    li {
      list-style-position: inside;
      list-style-type: disc;
    }
  }

  strong {
    color: inherit;
    font-weight: default(weight, bold);
  }

  em {
    font-style: italic;
  }

  p {
    a {
      color: color(primary);
      text-decoration: underline;
    }

    & + p {
      padding-top: 2rem;

      @media (min-width: $md-width) {
        padding-top: 3rem;
      }
    }
  }

  img {
    height: auto;
    max-width: 100%;
  }

  .table-responsive {
    display: block;
    overflow-x: auto;
    width: 100%;
  }

  blockquote,
  blockquote * {
    color: color(text);
    font-size: 1.8rem;
  }

  table {
    border: 1px solid color(border);
    margin-top: 3rem;
    width: 100%;
  }

  th,
  thead {
    font-weight: default(weight, bold);
  }

  td,
  th {
    padding: 0.5rem 0;
    text-align: left;
  }

  td {
    border-top: 1px solid color(border);
    padding: 1.5rem;
  }

  h4 {
    @include heading(12, true, false, 2);
    color: color(primary);
    font-weight: default(weight, bold);

    @media (min-width: $sm-width) {
      font-size: 1.4rem;
    }
  }

  h2 {
    margin-bottom: 2rem;
    margin-top: 3rem;

    @media (min-width: $md-width) {
      &:not(:first-of-type) {
        margin-top: 8rem;
      }
    }
  }

  h3 {
    margin-bottom: 1rem;
    margin-top: 2rem;

    @media (min-width: $md-width) {
      margin-bottom: 1.5rem;
      margin-top: 3rem;
    }
  }
}

/* haslightcontent */
.has-light-content {
  .h1,
  .h2,
  .h3,
  .h4,
  p {
    color: color(text, light);
  }
}
