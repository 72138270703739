.collapse {
  &-content {
    position: relative;

    @media (max-width: $xs-width) {
      max-height: 30rem;
      overflow: hidden;
      transition: all 0.5s;
      &:before {
        @include poa(null, 0, 0, 0);
        background: #fff;
        background: linear-gradient(0deg, #fff, hsla(0, 0%, 100%, 0.0203432));
        bottom: 0;
        content: "";
        height: 40%;
        left: 0;
        position: absolute;
        width: 100%;
        z-index: 3;
      }
    }
  }

  @media (max-width: $xs-width) {
    &-ctn {
      &.open {
        .collapse-content {
          max-height: 1000px;
          &:before {
            display: none;
          }
        }
        .collapse-btn {
          display: none;
        }
      }
    }
  }
}
