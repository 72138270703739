.table-div {
  line-height: 20px;
  margin-bottom: 20px;
  overflow: hidden;

  @media (min-width: $md-width) {
    border: 1px solid color(border);
    border-radius: default(radius);
    margin-bottom: 30px;
  }

  .table-head {
    background: none;
    color: color(text);
    display: block;
    font-weight: default(weight, bold);
    overflow: hidden;
    padding: 5px 0;
    text-transform: uppercase;

    @media (min-width: $md-width) {
      background: color(background);
      display: flex;
      padding: 15px 0;
    }

    &-cell {
      display: none;

      @media (min-width: $md-width) {
        display: inline-block;
      }
    }
  }

  .table-row {
    border: 1px color(border, dark);
    overflow: hidden;
    padding: 5px 0;
    position: relative;
    width: 100%;

    @media (min-width: $md-width) {
      border: 0;
      display: flex;
      padding: 0;
    }

    .table-cell {
      padding: 5px 10px;

      @media (min-width: $md-width) {
        display: inline-block;
        padding: 15px;
      }
    }

    ul {
      margin: 0;
    }
  }

  .title-mobile {
    color: color(mono, dark);
    display: block;

    @media (min-width: $md-width) {
      display: none;
    }
  }

  .label-mobile {
    color: color(text);
    display: inline-block;
    font-weight: default(weight, bold);

    @media (min-width: $md-width) {
      display: none;
    }
  }
}
