.alert {
  border-radius: default(radius);
  margin-bottom: 1.5rem;
  padding: 0.7rem;

  &-fw {
    text-align: center;
    width: 100%;
  }

  &-success {
    background-color: transparentize(color(alert, success), 0.8);
    border: color(alert, success);
    color: color(alert, success);
  }

  &-warning {
    background-color: transparentize(color(alert, warning), 0.8);
    border: color(alert, warning);
    color: color(alert, warning);
  }

  &-danger {
    background-color: transparentize(color(alert, danger), 0.8);
    border: color(alert, danger);
    color: color(alert, danger);
  }

  &-info {
    background-color: transparentize(color(alert, info), 0.8);
    border: color(alert, info);
    color: color(alert, info);
  }
}

.label {
  &-success {
    color: color(alert, success);
  }

  &-warning {
    color: color(alert, warning);
  }

  &-danger {
    color: color(alert, danger);
  }
}
