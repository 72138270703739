.product-row {
  border-bottom: 1px solid color(border);
  padding: 2rem 0;
  position: relative;
  &:first-child {
    border-top: 1px solid color(border);
  }

  @media (min-width: $sm-width) {
    > div {
      flex-wrap: nowrap;
    }
  }
  @media (min-width: $md-width) {
    border-radius: default(radius);
    padding: 3rem 0;
  }
  .product-name {
    line-height: 1.5;
    min-height: 65px;
    padding-right: 3rem;

    @media (min-width: $md-width) {
      min-height: 0;
      padding-right: 0;
    }
  }
  .product-attributes {
    @media (min-width: $md-width) {
      line-height: 1.5;
    }
  }
  .product-qty {
    max-width: 17.5rem;
    &-ctn {
      margin-bottom: 0;
    }

    @media (min-width: $md-width) and (max-width: $lg-width) {
      &-down,
      &-up {
        width: 30px;
      }
    }
  }
  .product-delete {
    font-size: 1.2rem;
    position: absolute;
    right: 0;
    top: 1.5rem;

    @media (min-width: $md-width) {
      &:hover {
        color: color(primary, dark);
      }
    }
    @media (min-width: $sm-width) {
      position: static;
    }
  }

  .current-price {
    font-size: 1.4rem;

    @media (min-width: $md-width) {
      font-size: 1.6rem;
    }
  }

  .product-price-old {
    font-size: 1.2rem;
    margin-top: 1rem;

    @media (min-width: $md-width) {
      font-size: 1.4rem;
    }
  }
  .product-infos {
    height: auto;
    margin-bottom: 1.5rem;
    margin-top: 0;

    @media (min-width: $md-width) {
      margin-bottom: 0;
    }
  }
  .product-total {
  }

  .product-img {
    max-width: 65px;

    @media (min-width: $sm-width) {
      max-width: 8rem;
    }
    @media (min-width: $lg-width) {
      max-width: 13.5rem;
    }

    &-ctn {
      @include poa(2rem, auto, auto, 0);
      padding: 0;

      @media (min-width: $sm-width) {
        padding: 0.2rem;
        position: static;
      }
    }
  }

  .product-availability {
    margin-top: 1rem;
  }
}
