.page-heading {
  margin-bottom: 15px;
  &-inner {
    padding: 25px 0;

    @media (min-width: $xs-width) {
      margin-bottom: 20px;
    }
    @media (min-width: $sm-width) {
      margin-bottom: 25px;
      padding: 35px 0;
    }
    @media (min-width: $md-width) {
      margin-bottom: 35px;
    }
    @media (min-width: $lg-width) {
      margin-bottom: 45px;
      padding: 45px 0;
    }
  }
  &-content {
    border-bottom: 1px solid color(border);
  }
  &-desc {
    border-top: 1px solid color(border);
  }

  @media (min-width: $md-width) {
    border: 0;
  }

  &.order-process,
  &.authentication {
    margin-bottom: 2rem;
    padding: 0;
    .page-heading-content {
      border: 0;
    }

    .page-heading-title {
      margin-bottom: 0;
      margin-top: 1rem;
      text-align: center;

      @media (min-width: $md-width) {
        margin-bottom: 3.5rem;
        margin-top: 8.5rem;
        max-width: none;
        text-align: left;
      }
    }

    .helper-text {
      font-family: default(font-family);
      font-size: 1.5rem;
      font-weight: default(weight);
      letter-spacing: 0.05rem;
      margin-top: 0.5rem;
      text-transform: none;
    }

    .short-desc {
      font-family: default(font-family);
      font-size: 1.5rem;
      font-weight: default(weight);
      line-height: 2rem;
      margin-bottom: 2rem;
      margin-top: 0.5rem;
      text-transform: none;
    }
  }
}
