/* global style - blocs produits */
.product-name {
  @include rem(14);
  color: color(mono, dark);
  display: block;

  @media (min-width: $md-width) {
    @include rem(16);
  }
}

.product-attributes {
  @include rem(11);

  @media (min-width: $md-width) {
    @include rem(12);
  }
}

.pin {
  font-size: 1.1rem;
}

/* in product-ctn (listing) */
.product-ctn {
  margin-bottom: 15px;
  overflow: hidden;
  padding-left: 0.75rem;
  padding-right: 0.75rem;

  @media (min-width: $xs-width) {
    margin-bottom: 20px;
  }
  @media (min-width: $sm-width) {
    margin-bottom: 30px;
    padding-left: 1rem;
    padding-right: 1rem;
  }
  @media (min-width: $md-width) {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  .old-price {
    font-size: 1.2rem;

    @media (min-width: $md-width) {
      font-size: 1.4rem;
    }
  }

  .current-price {
    font-size: 1.4rem;
    margin-top: 1rem;

    @media (min-width: $md-width) {
      font-size: 1.6rem;
    }
  }

  .inner {
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: hidden;
    padding-bottom: 3rem;
    position: relative;
  }

  .product-pins {
    @include poa(0, null, null, 0);

    li {
      @include rem(12);
      background: color(background, dark);
      color: color(mono, light);
      letter-spacing: 1px;
      padding: 5px 0;
    }
  }

  .product-infos {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
    padding-top: 1rem;

    @media (min-width: $md-width) {
      padding-top: 2rem;
    }

    .product-category {
      font-size: 1.1rem;
      text-align: center;
      text-transform: capitalize;

      @media (min-width: $md-width) {
        font-size: 1.2rem;
      }
    }

    .btn {
      padding: 5px 10px;

      @media (min-width: $sm-width) {
        padding: 5px 20px;
      }

      &.ajax_add_to_cart_button {
        margin-bottom: 5px;
      }
    }
  }

  .product-name {
    padding: 0 0.5rem;
    text-align: center;

    @media (min-width: $sm-width) {
      padding: 0;
    }
  }

  .product-details {
    padding: 7px 0;
  }

  .current-price {
    display: block;
    text-align: center;
    width: 100%;
  }

  &.alt {
    .inner {
      border-color: color(border);
    }

    .product-infos {
      background: color(background);
    }

    .product-name {
      color: color(text);
    }
  }

  .add-to-cart {
    display: block;

    @media (min-width: $md-width) {
      @include poa(null, 0, -6rem, 0);
      background-color: #000;
      display: block;
      transition: 0.3s;
    }
  }

  &:hover {
    @media (min-width: $md-width) {
      .add-to-cart {
        bottom: 0;
      }
    }
  }

  .product-img {
    &__hover {
      inset: 0 0 0 0;
      opacity: 0;
      position: absolute;
      transition: opacity 0.3s;
    }

    &:hover {
      .product-img__hover {
        opacity: 1;
      }
    }
  }
}

.owl-carousel.owl-product {
  .inner {
    border-bottom: 1px solid transparent;
    position: relative;

    &:hover {
      border-color: transparent;
    }

    @media (min-width: $md-width) {
      &:hover {
        border-bottom: 1px solid #000;
        transition: all 0.3s ease 0s;
      }
    }
  }
}
