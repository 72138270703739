.tile {
  background-position: center;
  background-size: cover;
  position: relative;

  &.has-overlay::before {
    @include poa(0, 0, 0, 0);
    background-color: color(overlay, light);
    content: "";
  }

  &-content {
    padding-bottom: 10%;
    padding-top: 10%;
    position: relative;
  }

  .btn-mea {
    margin-top: 1.5rem;
  }

  &-oblong {
    .tile-title {
      display: flex;
      height: 15rem;
    }
  }

  &-info {
    a {
      display: block;
    }

    .tile-content {
    }

    .img-icon {
      bottom: 8rem;
      max-width: 40%;
    }

    .wysiwyg {
      margin-bottom: 3rem;
      min-height: 13.5rem;
    }
  }
}
