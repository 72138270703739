.cookies {
  @include position(fixed, null, 0, 0, 0);
  background-color: color(background);
  color: color(mono, dark);
  font-size: 1.4rem;
  z-index: 16000003;

  &-content-inner {
    @media (min-width: $md-width) {
      flex-wrap: nowrap;
      font-size: 1.6rem;
      text-align: left;
    }
  }

  .link {
    color: color(mono, dark);

    &::after {
      border-color: color(mono, dark);
      opacity: 1;
    }

    &:hover {
      &::after {
        opacity: 0;
      }
    }
  }

  .cookies-btn {
    margin-top: 1.5rem;

    @media (min-width: $md-width) {
      margin-left: 1.5rem;
      margin-top: 0;
    }
  }

  &-content {
    padding: 1rem;

    p {
      line-height: 1.2;
    }
  }
}
