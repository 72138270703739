.panel-open {
  overflow: hidden;

  .gototop {
    display: none;
  }
}

.panel {
  background-color: color(mono, light);
  bottom: 0;
  height: 100%;
  max-width: 100%;
  overflow-x: auto;
  position: fixed;
  right: -100%;
  top: 0;
  transition: 0.3s ease;
  width: 100%;
  z-index: 1000;

  @media (min-width: $sm-width) {
    max-width: 100%;
    right: -530px;
    width: 520px;

    &-fw {
      right: -100%;
      width: 100%;
    }
  }
  @media (min-width: $md-width) {
    max-width: 100%;
    right: -600px;
    width: 595px;
  }

  &-head {
    background: #f2f2f2;
    border-bottom: 0.1rem solid color(border, light);
    color: color(text, light);
    padding: 1.4rem 2rem;
    position: relative;

    @media (min-width: $md-width) {
      padding: 3.5rem 5rem;
    }
  }

  &-block {
    margin: 1.5rem 1.5rem;

    @media (min-width: $md-width) {
      margin: 3rem 5rem;
    }
  }

  &-message {
    margin: 2rem 5rem 0;
  }

  &-messages {
    margin-bottom: 1.5rem;
    width: 100%;
  }

  &-title {
    align-items: center;
    color: color(text);
    display: flex;
    font-size: 2rem;
    position: relative;
    width: 100%;
    z-index: 2;

    @media (min-width: $md-width) {
      font-size: 2.4rem;
    }
  }

  &-close {
    @include poa(50%, 0);
    color: color(primary);
    cursor: pointer;
    font-size: 1.4rem;
    padding: 1.5rem 2.5rem;
    transform: translateY(-50%);
    transition: 0.3s;
    z-index: 3;

    &:hover {
      color: color(text);
    }
  }

  &-message {
    display: none;
    padding: 10px 30px;
  }

  &-buttons {
    padding: 30px;
  }

  &-footer {
    margin-top: 15px;
  }

  &.open {
    right: 0;
  }

  &-overlay {
    background-color: color(overlay);
    bottom: 0;
    left: 0;
    opacity: 0;
    pointer-events: none;
    position: fixed;
    right: 0;
    top: 0;
    transition: 0.5s ease 0s;
    z-index: 200;
  }

  &-content {
    padding-bottom: 3rem;
    width: 100%;
  }

  .small-ctn {
    padding: 3rem 5rem;
  }
}

.panels {
  &.open {
    .panel-overlay {
      opacity: 1;
      pointer-events: auto;
    }
  }
}

.panel-cms {
  z-index: 1100;

  .panel-content {
    padding: 3rem;
  }
}

.panel-product {
  position: relative;

  &-img-ctn {
    padding: 0 15px;
    position: relative;
    width: 31%;
  }

  &-reduction {
    @include poa(5px, null, null, 20px);
    background: color(mono, dark);
    border-radius: 50%;
    color: color(mono, light);
    display: inline-block;
    font-size: 10px;
    font-weight: default(weight, bold);
    height: 30px;
    line-height: 30px;
    text-align: center;
    width: 30px;
  }

  &-infos {
    padding: 0 15px 0 0;
    position: relative;
    vertical-align: middle;
    width: 69%;

    .panel-product-name {
      font-size: 16px;
      margin-bottom: 10px;
    }

    .panel-product-attributes {
      color: color(text);
      font-size: 12px;
      line-height: 1.5rem;
      margin-bottom: 20px;
    }

    .panel-product-prices {
      margin-top: 1.5rem;

      .panel-product-old-price {
        font-size: 12px;
      }
    }
  }
}

.panel-cart-add {
  .panel-content {
    padding-bottom: 0;
  }

  .btn {
    background-color: #000;
  }

  .crossseling {
    margin-right: -30px;
    overflow-x: hidden;
  }

  .owl-product {
    width: 95%;

    .owl-stage-outer {
      overflow: visible;
    }

    .product-ctn {
      .add-to-cart {
        bottom: 0;
        display: block;
      }
    }
  }
}

.panel-account {
  .submit {
    margin-top: 30px;
  }
}

.account-menu-link {
  display: block;
  padding: 2rem 3rem;
}
