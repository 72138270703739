.jauge-icons {
  @include poa(0, 0, 0, 0);
  display: inline-block;
  text-align: left;
  .icon {
    display: inline-block;
    font-size: 2.3rem;
    height: 2.3rem;
    text-align: center;
    width: 2.3rem;
  }
  &-ctn {
    display: inline-block;
    height: 2.5rem;
    margin-bottom: 0.5rem;
    margin-top: 0.5rem;
    position: relative;
    width: 13rem;
  }

  @media (min-width: $md-width) {
    &-ctn {
      height: 3rem;
      margin: auto;
      width: 15rem;
    }
    .icon {
      font-size: 2.7rem;
      height: 2.7rem;
      width: 2.7rem;
    }
  }
  &-ctn.is-small {
    height: 1.5rem;
    margin: 0;
    width: 8rem;
    .icon {
      font-size: 1.2rem;
      height: 1.1rem;
      width: 1.1rem;
    }
  }
}
