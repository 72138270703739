.popinstore {
  @include position(fixed, 0, 0, 0, 0);
  align-items: center;
  background-color: color(overlay);
  display: flex;
  justify-content: center;
  text-align: center;
  z-index: 3000;

  &-content {
    /* background: radial-gradient(circle at 0% 100%, #fff 15%, #fff 15%) no-repeat,
    radial-gradient(circle at 100% 0%, #fff 15%, #fff 15%) no-repeat,
    radial-gradient(circle at 0% 0%, transparent 7%, #fff 7%) no-repeat,
    radial-gradient(circle at 100% 100%, transparent 7%, #fff 7%) no-repeat;
    background-position: 0% 100%, 100% 0%, 0% 0%, 100% 100%;
    background-size: 75% 75%; */
    background-color: #fff;
    margin: 0 auto;
    max-width: 100%;
    padding: 3rem 2rem;
    width: 63rem;

    @media (min-width: $md-width) {
      padding-left: 11rem;
      padding-right: 11rem;
    }
  }

  &-shop-btn {
    border-width: 1px;

    @media (max-width: $xs-width + 1) {
      font-size: 1rem;
    }
  }

  &-shop-item {
    padding-left: 7.5px;
    padding-right: 7.5px;

    @media (min-width: $md-width) {
      padding-left: 1rem;
      padding-right: 1rem;
    }
  }
}
