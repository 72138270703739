#countries .countries_ul_hover {
  background: color(secondary, base);
  left: 0;
  right: auto;
  width: auto;
}

#languages_block_top > p {
  margin-right: 10px;
}

p.selected_language {
  position: relative;

  &:after {
    color: color(primary, base);
    content: "\e901";
    font-family: "icomoon";
    font-size: 1.2rem;
    margin-left: 3px;
    vertical-align: middle;
  }
}

.header-stack .selected_language:after {
  color: color(primary, base);
}
